import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  Container,
  Col,
  Row,
} from "reactstrap";
import { useFormik, FormikProvider, Field } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "../../style/style.css";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import Navmenu from "../Navmenu";
import AccessDenied from "../../Pages/AccessDenied";
import ReactDOMServer from "react-dom/server";

const validate = (values) => {
  const errors = {};
  if (!values.Name) errors.Name = "";
  return errors;
};

const ProjectsUpload = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [count, setCount] = useState(0);
  const [complete, setComplete] = useState(false);
  const [doneCount, setDoneCount] = useState(false);
  const [values, setValues] = useState([]);

  const formik = useFormik({
    initialValues: {
      Year: "",
      No: "",
      Titul: "",
      Name: "",
      Street: "",
      City: "",
      PSC: "",
      IC: "",
      DIC: "",
      Vystaveni: "",
      Splatnost: "",
      Zpusob: "",
      Vs: "",
      Ucet: "",
      CelkemCena: "",
      Mena: "",
      Tabulka: "",
      Veta: "",
    },
    onSubmit: (values) => {
      console.log(renderTable());
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/AboutMe/faktura`,
          {
            Year: values.Year,
            No: values.No,
            Titul: values.Titul,
            Name: values.Name,
            Street: values.Street,
            City: values.City,
            PSC: values.PSC,
            IC: values.IC,
            DIC: values.DIC,
            Vystaveni: values.Vystaveni,
            Splatnost: values.Splatnost,
            Zpusob: values.Zpusob,
            Vs: values.Vs,
            Ucet: values.Ucet,
            CelkemCena: values.CelkemCena,
            Mena: values.Mena,
            Tabulka: values.Tabulka,
            Veta: values.Veta,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/admin/faktury/list");
        })
        .catch((err) => {});
    },
  });

  function renderTable() {
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <section class="">
        <div>
          <table class="col-12">
            <tr>
              <th class="col-7"></th>
              <th class="col-1">Mn.</th>
              <th class="col-2">Cena</th>
              <th class="col-2">Celkem</th>
            </tr>
            <tr>
              <td>
                <hr class="line2" />
              </td>
              <td>
                <hr class="line2" />
              </td>
              <td>
                <hr class="line2" />
              </td>
              <td>
                <hr class="line2" />
              </td>
            </tr>
            {renderBody()}
          </table>
        </div>
        <div></div>
      </section>
    );
    formik.values.Tabulka = htmlString;
    return htmlString;
  }

  function renderBody() {
    return values.map((item, index) => {
      if (isNaN(realParseFloat(item.cena))) {
        var tempN = realParseFloat(item.cena);
        var temp1 = round(tempN);
        var temp2 = replaceDotWithComma(temp1);
        var temp3 = CommaFormatted(temp2);

        return (
          <tr key={index}>
            <td class="col-7 nazev">{item.nazev}</td>
            <td class="col-1"> </td>
            <td class="col-2"> </td>
            <td class="col-2"> </td>
          </tr>
        );
      } else if (!isNaN(realParseFloat(item.cena))) {
        var tempN = realParseFloat(item.cena);
        var temp1 = round(tempN);
        var temp2 = replaceDotWithComma(temp1);
        var temp3 = CommaFormatted(temp2);

        return (
          <tr key={index}>
            <td class="col-7 nazev">{item.nazev}</td>
            <td class="col-1">{item.mnozstvi}</td>
            <td class="col-2">
              {temp3} {formik.values.Mena}
            </td>
            <td class="col-2">
              {item.suma} {formik.values.Mena}
            </td>
          </tr>
        );
      }
    });
  }

  const replaceDotWithComma = (str) => {
    return str.replace(/\./g, ",");
  };

  function round(x) {
    return (Math.round(x * 100) / 100).toFixed(2);
  }

  function CommaFormatted(x) {
    var temp = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return temp;
  }

  const handleChange = (e) => {
    e.preventDefault();
    const updatedAreas = [...values];
    updatedAreas[e.target.id].nazev = e.target.value;
    setValues(updatedAreas);
  };
  const handleChangeMnozstvi = (e) => {
    e.preventDefault();
    const updatedAreas = [...values];
    updatedAreas[e.target.id].mnozstvi = e.target.value;
    setValues(updatedAreas);
    handleChangeSuma(e);
  };
  const handleChangeCena = (e) => {
    e.preventDefault();
    const updatedAreas = [...values];
    updatedAreas[e.target.id].cena = e.target.value;
    setValues(updatedAreas);
    handleChangeSuma(e);
  };
  const handleChangeSuma = (e) => {
    e.preventDefault();
    var cena = values[e.target.id].cena;
    var mn = values[e.target.id].mnozstvi;
    var temp = parseFloat(cena) * parseInt(mn);
    var temp1 = round(temp);
    var temp2 = replaceDotWithComma(temp1);
    var temp3 = CommaFormatted(temp2);
    values[e.target.id].suma = temp3;
  };

  function renderForm() {
    const array = [];
    for (var i = 0; i < count; i++) {
      array.push(
        <>
          <Row>
            <Card
              key={i}
              id={i}
              className="border-0 shadow-lg p-3 mb-3 me-1 bg-dark rounded"
            >
              <CardBody className="bg-dark text-light">
                <Row>
                  <FormGroup className="my-2">
                    <Label for="nazev">Název</Label>
                    <Input
                      name="nazev"
                      id={i}
                      type="text"
                      onChange={handleChange}
                      value={values[i].nazev}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="mnozstvi">Množství</Label>
                      <Input
                        name="mnozstvi"
                        id={i}
                        type="text"
                        onChange={handleChangeMnozstvi}
                        value={values[i].mnozstvi}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm>
                    <FormGroup>
                      <Label for="cena">Cena</Label>
                      <Input
                        name="cena"
                        id={i}
                        type="text"
                        onChange={handleChangeCena}
                        value={values[i].cena}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm>
                    <FormGroup>
                      <Label for="suma">Celkem cena</Label>
                      <Input
                        name="suma"
                        id={i}
                        type="text"
                        onChange={handleChangeSuma}
                        value={values[i].suma}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </>
      );
    }
    if (complete && doneCount === false) {
      return array;
    }
  }

  function setArray(count) {
    for (var i = 0; i < count - values.length; i++) {
      values.push({ nazev: "", mnozstvi: "", cena: "", suma: "" });
    }
  }

  function realParseFloat(s) {
    s = s.replace(/[^\d,.-]/g, ""); // strip everything except numbers, dots, commas and negative sign
    if (
      navigator.language.substring(0, 2) !== "de" &&
      /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(s)
    ) {
      // if not in German locale and matches #,###.######
      s = s.replace(/,/g, ""); // strip out commas
      return parseFloat(s); // convert to number
    } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(s)) {
      // either in German locale or not match #,###.###### and now matches #.###,########
      s = s.replace(/\./g, ""); // strip out dots
      s = s.replace(/,/g, "."); // replace comma with dot
      return parseFloat(s);
    } // try #,###.###### anyway
    else {
      s = s.replace(/,/g, ""); // strip out commas
      return parseFloat(s); // convert to number
    }
  }

  function renderCelkem() {
    var temp = 0;
    values.map((item) => {
      let output = realParseFloat(item.suma);
      if (isNaN(output)) {
        output = 0;
      }
      temp = temp + output;
    });
    var temp1 = round(temp);
    var temp2 = replaceDotWithComma(temp1);
    var temp3 = CommaFormatted(temp2);
    formik.values.CelkemCena = temp3;

    if (doneCount) {
      return (
        <>
          <Row>
            <Card className="border-0 shadow-lg p-3 mb-3 me-3 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="CelkemCena">Celkem cena</Label>
                    <Input
                      name="CelkemCena"
                      id="CelkemCena"
                      type="text"
                      value={formik.values.CelkemCena}
                      disabled
                    />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <Card className="border-0 shadow-lg p-3 mb-3 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="Mena">Měna</Label>
                    <Input
                      name="Mena"
                      id="Mena"
                      type="text"
                      value={formik.values.Mena}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </>
      );
    }
  }

  function setData(count) {
    console.log(values);
    setCount(count);
    setArray(count);
  }

  function renderCounter() {
    if (complete && doneCount === false) {
      return (
        <>
          <Card className="border-0 shadow-lg p-3 mb-3 bg-dark rounded">
            <CardBody className="bg-dark text-light">
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="m-2">
                  <Label for="count">Počet položek</Label>
                  <Input
                    name="count"
                    id="count"
                    type="text"
                    value={count}
                    disabled
                  />
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
          <Button
            color="warning"
            className="mb-3 me-3"
            onClick={() => setData(count + 1)}
          >
            Přidat položku
          </Button>
          <Button
            className="btn btn-success mb-3"
            onClick={() => setDoneCount(true)}
          >
            Uložit položky
          </Button>
        </>
      );
    }
  }

  function renderDefault() {
    if (!complete) {
      return (
        <>
          <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
            <CardBody className="bg-dark text-light">
              <Row>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Year">Rok</Label>
                    <Input
                      name="Year"
                      id="Year"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Year}
                      invalid={Boolean(formik.errors.Year)}
                      valid={formik.touched.Year}
                    />
                  </FormGroup>
                </Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="No">Číslo</Label>
                    <Input
                      name="No"
                      id="No"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.No}
                      invalid={Boolean(formik.errors.No)}
                      valid={formik.touched.No}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Titul">Titul</Label>
                    <Input
                      name="Titul"
                      id="Titul"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Titul}
                      invalid={Boolean(formik.errors.Titul)}
                      valid={formik.touched.Titul}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Name">Jméno a příjmení</Label>
                    <Input
                      name="Name"
                      id="Name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      invalid={Boolean(formik.errors.Name)}
                      valid={formik.touched.Name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Street">Ulice</Label>
                    <Input
                      name="Street"
                      id="Street"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Street}
                      invalid={Boolean(formik.errors.Street)}
                      valid={formik.touched.Street}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="City">Město</Label>
                    <Input
                      type="text"
                      name="City"
                      id="City"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.City}
                      invalid={Boolean(formik.errors.City)}
                      valid={formik.touched.City}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="PSC">PSČ</Label>
                    <Input
                      type="text"
                      name="PSC"
                      id="PSC"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.PSC}
                      invalid={Boolean(formik.errors.PSC)}
                      valid={formik.touched.PSC}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="IC">IČ</Label>
                    <Input
                      type="text"
                      name="IC"
                      id="IC"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.IC}
                      invalid={Boolean(formik.errors.IC)}
                      valid={formik.touched.IC}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm></Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="DIC">DIČ</Label>
                    <Input
                      type="text"
                      name="DIC"
                      id="DIC"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.DIC}
                      invalid={Boolean(formik.errors.DIC)}
                      valid={formik.touched.DIC}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Zpusob">Způsob platby</Label>
                    <Input
                      list="platby"
                      name="Zpusob"
                      id="Zpusob"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Zpusob}
                      invalid={Boolean(formik.errors.Zpusob)}
                      valid={formik.touched.Zpusob}
                    >
                    </Input>
                    <datalist id="platby">
                      <option value="Hotově">Hotově</option>
                      <option value="Bankovní převod">Bankovní převod</option>
                      <option value="Bar Zahlung">Bar Zahlung</option>
                      <option value="Überweisung">Überweisung</option>
                    </datalist>
                  </FormGroup>
                </Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Vystaveni">Datum vystavení</Label>
                    <Input
                      type="date"
                      name="Vystaveni"
                      id="Vystaveni"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Vystaveni}
                      invalid={Boolean(formik.errors.Vystaveni)}
                      valid={formik.touched.Vystaveni}
                    >
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Vs">VS</Label>
                    <Input
                      type="text"
                      name="Vs"
                      id="Vs"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Vs}
                      invalid={Boolean(formik.errors.Vs)}
                      valid={formik.touched.Vs}
                    />
                  </FormGroup>
                </Col>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Splatnost">Datum splatnosti</Label>
                    <Input
                      type="date"
                      name="Splatnost"
                      id="Splatnost"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Splatnost}
                      invalid={Boolean(formik.errors.Splatnost)}
                      valid={formik.touched.Splatnost}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Ucet">Bankovní účet</Label>
                    <Input
                      list="ucty"
                      name="Ucet"
                      id="Ucet"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Ucet}
                      invalid={Boolean(formik.errors.Ucet)}
                      valid={formik.touched.Ucet}
                    >
                    </Input>
                    <datalist id="ucty">
                      <option value="670100-2213845663/6210">670100-2213845663/6210</option>
                      <option value="DE39 5001 0517 5422 7293 00">DE39 5001 0517 5422 7293 00</option>
                    </datalist>
                  </FormGroup>
                </Col>
                <Col sm></Col>
              </Row>
              <Row>
                <Col sm>
                  <FormGroup className="m-2">
                    <Label for="Veta">Fakturace text</Label>
                    <Input
                      list="text-fakturace"
                      name="Veta"
                      id="Veta"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Veta}
                      invalid={Boolean(formik.errors.Veta)}
                      valid={formik.touched.Veta}
                    />
                    <datalist id="text-fakturace">
                      <option value="Za projekční práci Vám vystavuji fakturu ve výši:">Za projekční práci Vám vystavuji fakturu ve výši:</option>
                    </datalist>
                  </FormGroup>
                </Col>
                <Col sm></Col>
              </Row>
              <div>
                <Button
                  className="btn btn-success m-2"
                  onClick={() => setComplete(true)}
                >
                  Uložit
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      );
    }
  }

  function renderButtonSubmit() {
    if (doneCount) {
      return (
        <>
          <Row className="col-3 mb-3 mx-md-3">
            <Button type="submit" className="btn btn-success">
              Uložit fakturu
            </Button>
          </Row>
        </>
      );
    }
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <div>
        <Navmenu />
        <Container className="col-12">
          <div className="text-center">
            <Button
              className="btn-primary mt-3 mb-3 mx-3"
              tag={Link}
              to="/admin/faktury/list"
            >
              Zpět na výpis faktur
            </Button>
          </div>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Row>{renderDefault()}</Row>
              <Row>
                <Col md>{renderCounter()}</Col>
                <Col md>{renderForm()}</Col>
              </Row>
              <Row>
                <Col md>{renderCelkem()}</Col>
                <Col md>{renderButtonSubmit()}</Col>
              </Row>
            </Form>
          </FormikProvider>
        </Container>
      </div>
    );
  }
};

export default ProjectsUpload;
