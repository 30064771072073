import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, CardTitle, Button, Container } from "reactstrap";
import axios from "axios";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import Loading from "./Loading";

const AccessDenied = (props) => {
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [ isLoading, setIsLoading ] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, accessToken]);

  if(isLoading) {
      return (
          <>
            <Loading />
          </>
      )
  }
  if (accessToken) {
    history.push(`${props.match.params}`);
  }
  if (!accessToken) {
    return (
      <>
        <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Card className="bg-dark shadow-lg p-3 mb-5 bg-dark rounded col-sm-6">
            <CardBody>
              <CardTitle tag="h1" className="text-white">
                Na tuto stránku nemáte dostatečná práva!
              </CardTitle>
              <Button className="mt-5 mb-3" color="danger" tag={Link} to="/">
                Zpět na hlavní stránku
              </Button>
              <Button
                className="mt-5 mb-3 mx-3 text-light"
                color="success"
                tag={Link}
                to="/admin"
              >
                Přihlásit se
              </Button>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
};

export default AccessDenied;
