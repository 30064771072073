import React, { useCallback, useEffect, useState, useContext } from "react";
import { Alert, Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "../Navmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPhotoVideo,
  faTimes,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const ProjectsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [indexItem, setIndexItem] = useState();
  const [item, setItem] = useState();
  const [{ accessToken }] = useContext(ApplicationContext);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    function renderCheck(check) {
      if (check) {
        return (
          <>
            <Button color="success" refresh="true" disabled={check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
      if (!check) {
        return (
          <>
            <Button color="secondary" refresh="true" disabled={!check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
    }
    const array = response.map((item, index) => {
      return (
        <tr key={item.id} className="text-white align-middle">
          <td>{item.name}</td>
          <td>{item.nameDe}</td>
          <td>{item.type}</td>
          <td>{item.typeDe}</td>
          <td>{item.year}</td>
          <td>{renderCheck(item.isActive)}</td>
          <td>
            <Button
              color="warning"
              refresh="true"
              tag={Link}
              to={`/admin/image/list/${item.id}`}
            >
              <FontAwesomeIcon icon={faPhotoVideo} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="success"
              refresh="true"
              tag={Link}
              to={`/admin/project/edit/${item.id}`}
            >
              <FontAwesomeIcon icon={faPencilAlt} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="danger"
              refresh="true"
              onClick={() => {
                handleClickOpen();
                setItem(item.id);
                setIndexItem(index);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  const removeItem = (index) => {
    response.splice(index, 1);
    setResponse([...response]);
  };

  function deleteProject() {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Projects/delete/${item}`)
      .then(() => {
        removeItem(indexItem);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
        setVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response;
  }

  function renderSuccess() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            Projekt odstraněn
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <Container>
            {renderSuccess()}
            {renderError()}
            <Button
              className="mt-3 btn-success"
              tag={Link}
              to="/admin/project/upload"
            >
              Přidat projekt
            </Button>
            <Button
              className="mt-3 mx-3 btn-success"
              tag={Link}
              to="/admin/project/sort"
            >
              Seřadit projekty
            </Button>
            <Table responsive className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded table-responsive-sm">
              <thead>
                <tr>
                  <th>Název (CZE)</th>
                  <th>Název (DE)</th>
                  <th>Typ (CZE)</th>
                  <th>Typ (DE)</th>
                  <th>Rok</th>
                  <th>Aktivní?</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderProjects()}</tbody>
            </Table>
          </Container>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Potvrďte, že chcete smazat tento projekt. Po smazání projektu už
                to nejde vzít zpět!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Zrušit
              </Button>
              <Button
                onClick={() => {
                  deleteProject();
                  handleClose();
                }}
                color="danger"
              >
                Potvrdit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default ProjectsList;
