import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import {
  Button,
  ButtonGroup,
  Input,
  Row,
  Col,
  Container,
  Table,
  Label
} from "reactstrap";
import axios from "axios";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import Loading from "../../Pages/Loading";

const ImageEdit = (props) => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [isMain, setIsMain] = useState();
  const [description, setDescription] = useState();
  const [descriptionDe, setDescriptionDe] = useState();
  const { id } = useParams();

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Images/getImage/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        setResponse(res.data);
        setIsMain(res.data.isMain);
        setDescriptionDe(res.data.descriptionDe);
        setDescription(res.data.description);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, []);

  function sendData() {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Images/editPosition`,
        {
          Id: response.id,
          isMain: isMain,
          Description: description,
          DescriptionDe: descriptionDe,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        history.push(`/admin/image/list/${response.projectId}`);
      })
      .catch((err) => {
      });
  }

  const handleChangeDes = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const handleChangeDe = (e) => {
    e.preventDefault();
    setDescriptionDe(e.target.value);
  };

  function renderData() {
    return (
      <>
        <Row>
          <Col>
            <img
              src={`data:image/jpeg;base64,${response.preview}`}
              className="imgSizeEdit mt-3 col-12 border-radius shadow-lg p-1 bg-dark rounded"
            />
          </Col>
          <Col>
            <Table className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded">
              <thead>
                <tr>
                  <th>
                    Je toto hlavní fotka: {isMain ? <i>"ANO"</i> : <i>"NE"</i>}
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th>Změnit jestli bude fotka hlavní:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ButtonGroup className="m-1">
                      <Button color="warning" onClick={() => setIsMain(true)}>
                        Hlavní
                      </Button>
                      <Button color="warning" onClick={() => setIsMain(false)}>
                        Normální
                      </Button>
                    </ButtonGroup>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <Label className="mb-2">Popis CZ</Label>
                      <Input
                        className="bg-light"
                        onChange={handleChangeDes}
                        value={description}
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <div className="">
                      <Label className="mb-2">Popis DE</Label>
                      <Input
                        className="bg-light"
                        onChange={handleChangeDe}
                        value={descriptionDe}
                      />
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <Button className="btn btn-success" onClick={() => sendData()}>
              Uložit
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <Container>
            <div className="text-center">
              <Button
                className="btn-primary mt-3 mx-3"
                tag={Link}
                to={`/admin/image/list/${response.projectId}`}
              >
                Zpět na výpis obrázků
              </Button>
            </div>
            {renderData()}
          </Container>
        </>
      );
    }
  }
};

export default ImageEdit;
