import React, { useCallback, useEffect, useState, useContext } from "react";
import { Alert, Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "../Navmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFileDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

const ProjectsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [indexItem, setIndexItem] = useState();
  const [done, setDone] = useState(false);

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/faktury`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  const removeItem = (index) => {
    response.splice(index, 1);
    setResponse([...response]);
  };

  function deleteProject(name, index) {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/faktura/${name}`)
      .then(() => {
        removeItem(index);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
        setVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response;
  }

  function downloaded(name) {
    const byte = "";
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/download/${name}`, {
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + accessToken,
      },
    })
    .then((response) => {
      byte = response.data;
    });
    var blob = new Blob([byte]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = name;
    link.download = fileName;
    link.click();
  }

  function open(name) {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/download/${name}`, {
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        window.open(response.data, "_blank")
      })
  }

  function renderProjects() {
    const array = response.map((item, index) => {
      return (
        <tr key={item.name} className="text-white align-middle">
          <td>{item.name}</td>
          <td>
            <Button
              color="success"
              refresh="true"
              onClick={() => open(item.name)}
            >
              <FontAwesomeIcon icon={faFileDownload} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="danger"
              refresh="true"
              onClick={() => {
                deleteProject(item.name, index);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <Container>
            {renderError()}
            <Button
              className="mt-3 btn-success"
              tag={Link}
              to="/admin/faktury/upload"
            >
              Přidat fakturu
            </Button>
            <Table responsive className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded table-responsive-sm">
              <thead>
                <tr>
                  <th>Název</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderProjects()}</tbody>
            </Table>
          </Container>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default ProjectsList;
