import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Link, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Loading from "../../Pages/Loading";

const DescriptionCZE = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const editorRef = useRef(null);
  const history = useHistory();
  const { id } = useParams();

  const log = () => {
    if (editorRef.current) {
      response.description = editorRef.current.getContent();
      var data = JSON.stringify(response);
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/Projects/descriptionCze`,
          { payload: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push(`/admin/project/edit/${id}`);
        })
        .catch((err) => {
        });
    }
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <div className="container mt-3 border-radius shadow-lg p-1 bg-dark rounded">
            <Button
              className="btn-primary mb-2"
              tag={Link}
              to={`/admin/project/edit/${id}`}
            >
              Zpět na projekt
            </Button>
            <Editor
              apiKey="ul6mdzl069gm6wnobufb6hvemuyx42jga41vk5v0g4i5uay9"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={response.description}
              init={{
                skin: "oxide-dark",
                content_css: "dark",
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists table link charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help " +
                  "insertfile media pageembed template link anchor codesample",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                imagetools_toolbar:
                  "rotateleft rotateright | flipv fliph | editimage imageoptions",
                contextmenu: "link image table",
                menubar: "file edit view insert format tools table tc help",
              }}
            />
            <Button className="btn btn-success mt-2" onClick={log}>
              Uložit
            </Button>
          </div>
        </>
      );
    }
  }
};

export default DescriptionCZE;
