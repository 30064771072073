import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faPinterestSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Input,
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import LayoutContact from "./layout/LayoutContact";
import Yuki from "./assets/images/navbarpngw.svg";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { useFormik, FormikProvider } from "formik";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./style/style.css";
import "./style/aboutme.css";
import "./style/hover.css";
import "./style/contact.css";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [{ lang }] = useContext(ApplicationContext);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const [done, setDone] = useState(false);
  const onDismiss = () => setVisible(false);

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = " ";
    }
    if (!values.email) {
      errors.email = " ";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email =
        lang === "De"
          ? "Ungültige E-Mail-Adresse"
          : "Neplatná e-mailová adresa";
    }
    if (!values.telNumber) {
      errors.telNumber = " ";
    } else if (
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(values.telNumber)
    ) {
      errors.telNumber =
        lang === "De" ? "Ungültige Telefonnummer" : "Neplatné telefonní číslo";
    }
    if (!values.text) {
      errors.text = " ";
    }
    return errors;
  };

  function renderAlert() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            {lang === "De"
              ? "Irgendwo ist ein Fehler aufgetreten, bitte versuchen Sie es erneut"
              : "Někde se stala chyba, zkuste to znovu"}
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            {lang === "De"
              ? "Ihre Nachricht wurde gesendet. Ich melde mich bald bei Ihnen zurück."
              : "Vaše zpráva byla odeslána. Brzy se Vám ozvu."}
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      telNumber: "",
      text: "",
    },
    validate: validate,
    onSubmit: (values) => {
      setError(false);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/AboutMe/sendEmail`,
          {
            email: values.email,
            fullName: values.fullName,
            telNumber: values.telNumber,
            text: values.text,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          setDone(true);
        })
        .catch(() => {
          setError(true);
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>Veronika Sulková | Kontakt</title>
        <meta name="description" content="Napište mi." />
        <meta itemprop="name" content="Veronika Sulková | Kontakt" />
        <meta itemprop="description" content="Napište mi." />
        <meta
          itemprop="image"
          content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
        />
        <meta property="og:url" content="https://veronika-sulkova.eu/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Veronika Sulková | Kontakt" />
        <meta property="og:description" content="Napište mi." />
        <meta
          property="og:image"
          content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Veronika Sulková | Kontakt" />
        <meta name="twitter:description" content="Napište mi." />
        <meta
          name="twitter:image"
          content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
        />
        <meta name="description" content="Napište mi." />
        <meta itemprop="name" content="Veronika Sulková | Kontakt" />
        <meta itemprop="description" content="Napište mi." />
      </Helmet>
      <LayoutContact>
        <div className="appColor">
          <section className="intro plainC min-vh-100">
            <div className="inner-content container">
              <Row>
                <Col md>
                  <h1 className="display-4 text-white mb-2">
                    {lang === "De" ? "KONTAKTIEREN SIE MICH" : "KONTAKTUJTE MĚ"}
                  </h1>
                  <FormikProvider value={formik}>
                    {renderAlert()}
                    <Form onSubmit={formik.handleSubmit}>
                      <FormGroup className="mt-2">
                        <Label for="fullName" className="text-white">
                          {lang === "De" ? "Name " : "Jméno "}{" "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="fullName"
                          id="fullName"
                          placeholder={
                            lang === "De" ? "Stefan Müller" : "Jan Novák"
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullName}
                          invalid={Boolean(formik.errors.fullName)}
                          valid={formik.touched.fullName}
                        />
                        {formik.errors.fullName ? (
                          <FormFeedback invalid>
                            {formik.errors.fullName}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="password" className="text-white">
                          E-mail{" "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="example@email.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={Boolean(formik.errors.email)}
                          valid={formik.touched.email}
                        />
                        {formik.errors.email ? (
                          <FormFeedback invalid>
                            {formik.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="telNumber" className="text-white">
                          {lang === "De"
                            ? "Telefonnummer "
                            : "Telefonní číslo "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="telNumber"
                          id="telNumber"
                          placeholder={
                            lang === "De"
                              ? "+39 777 888 999"
                              : "+420 777 888 999"
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.telNumber}
                          invalid={Boolean(formik.errors.telNumber)}
                          valid={formik.touched.telNumber}
                        />
                        {formik.errors.telNumber ? (
                          <FormFeedback invalid>
                            {formik.errors.telNumber}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="text" className="text-white">
                          {lang === "De" ? "Ihre Nachricht " : "Vaše zpráva "}{" "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          id="text"
                          placeholder={
                            lang === "De"
                              ? "Ihre Nachricht..."
                              : "Vaše zpráva..."
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.text}
                          invalid={Boolean(formik.errors.text)}
                          valid={formik.touched.text}
                        />
                        {formik.errors.text ? (
                          <FormFeedback invalid>
                            {formik.errors.text}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <div>
                        <p className="text-white mt-3 sizeReq">
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary"
                          >
                            *
                          </p>{" "}
                          - {lang === "De" ? "Pflichtfeld" : "Povinné"}
                        </p>
                        <Button
                          type="submit"
                          className={`${
                            formik.isValid ? "" : "disabled"
                          } mt-3 btns btn-successs`}
                        >
                          {lang === "De" ? "Senden" : "Odeslat"}
                        </Button>
                      </div>
                    </Form>
                  </FormikProvider>
                </Col>
                <Col md className="text-center">
                  <h1 className="display-4 text-white">KONTAKT</h1>
                  <Row className="contactText mt-3">
                    <a className="tel" href="mailto:veri.sulkova@gmail.com">
                      veri.sulkova@gmail.com
                    </a>
                  </Row>
                  <Row className="contactText">
                    <a
                      className="tel"
                      href="https://www.mojedatovaschranka.cz/sds/detail?dbid=k4yq359"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ID datové schránky: k4yq359
                    </a>
                  </Row>
                  <Row className="contactText">
                    <a
                      className="tel"
                      href="https://rejstriky.finance.cz/firma-dipl-ing-veronika-sulkova-m-sc-05236541"
                      target="_blank"
                      rel="noreferrer"
                    >
                      IČO: 05236541
                    </a>
                  </Row>
                  <Row className="contactText">
                    <a className="tel" href="tel:+420 721 518 460">
                      +420 721 518 460
                    </a>
                  </Row>
                  <Row className="mt-3 mb-3">
                    <div className="flexbox-centering">
                      <a
                        href="https://www.facebook.com/yukikitadesign/"
                        target="_blank"
                        className="btn-floating btn-sm"
                      >
                        <FontAwesomeIcon
                          className="fbico"
                          size="2x"
                          icon={faFacebookSquare}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/8yukikita8/"
                        target="_blank"
                        className="btn-floating btn-sm"
                      >
                        <FontAwesomeIcon
                          className="igico"
                          size="2x"
                          icon={faInstagram}
                        />
                      </a>
                      <a
                        href="https://cz.pinterest.com/yukikitadesign/_saved/"
                        target="_blank"
                        className="btn-floating btn-sm"
                      >
                        <FontAwesomeIcon
                          className="pintico"
                          size="2x"
                          icon={faPinterestSquare}
                        />
                      </a>
                    </div>
                  </Row>
                  <img src={Yuki} className="footersvg mb-3 mt-3" />
                  <Row>
                    <a className="text-white">
                      {new Date().getFullYear()} © Veronika Sulková
                    </a>
                  </Row>
                  <Row className="mt-3">
                    <a
                      href="https://tomas-kulhavy.cz"
                      target="_blank"
                      className="cby"
                    >
                      Made with ❤️ by @tomaskulhavy
                    </a>
                  </Row>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </LayoutContact>
    </>
  );
};

export default Projects;
