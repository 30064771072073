import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CardBody,
  Alert,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useFormik, FormikProvider } from "formik";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "E-mail musí být vyplněn";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Neplatná e-mailová adresa!";
  }
  return errors;
};

export default function ForgotPassword() {
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  function renderAlertEmail() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            Na Váš e-mail Vám byl zaslán odkaz na obnovení hesla.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    } else if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Někde se stala chyba, zadal jste správně e-mailovou adresu?
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: validate,
    onSubmit: (values) => {
      setError(false);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Account/forgotPassword`, {
          email: values.email,
          password: "",
        })
        .then((response) => {
          setDone(true);
        })
        .catch(() => {
          setError(true);
        });
    },
  });
  return (
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <Container>
        {renderAlertEmail()}
        <FormikProvider value={formik}>
          <Card className="border-radius col-sm-6 zarovnat shadow-lg p-3 mb-5 bg-dark rounded">
            <CardBody className="bg-dark text-light">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-70 d-block font-size-xl mb-1 text-uppercase">
                    Zapomenuté heslo
                  </small>
                  <span className="font-size-xxl mt-1"></span>
                </div>
              </div>
              <div className="text-center">
                <Button className="btn-dark mt-2 mb-2" tag={Link} to="/">
                  Zpět na domovskou obrazovku
                </Button>
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="m-2">
                  <Label for="email">E-mailová adresa</Label>
                  <Input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="jannovak@email.cz"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    invalid={Boolean(formik.errors.email)}
                    valid={formik.touched.email}
                  />
                  {formik.errors.email ? (
                    <FormFeedback invalid>{formik.errors.email}</FormFeedback>
                  ) : null}
                </FormGroup>
                <div>
                  <Button type="submit" className="m-2" color="success">
                    Restartovat heslo
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </FormikProvider>
      </Container>
    </div>
  );
}
