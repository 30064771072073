import React, { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Container,
  Button,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../style/style.css";
import Yuki from "../assets/images/navbarpngw.svg";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
  SET_LANG,
} from "../../providers/ApplicationProvider";

const NavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ lang }] = useContext(ApplicationContext);

  function renderButtonAdmin() {
    if (accessToken) {
      return (
        <>
          <Button
            className="btn btn-primary align-middle"
            tag={Link}
            to="/admin/list"
            size="sm"
          >
            ADMIN
          </Button>
        </>
      );
    }
  }

  
  function changeLang(lang) {
    dispatch({ type: SET_LANG, payload: lang });
  }

  return (
    <Navbar
      style={{ backgroundColor: "rgb(0,0,0)" }}
      className="navbar navbar-expand-lg navbar-toggleable-lg navbar-dark sticky-top"
    >
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img src={Yuki} className="navbarsvg mb-1 mt-1" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse
          className="d-lg-inline-flex flex-lg-row-reverse"
          isOpen={isOpen}
          navbar
        >
          <ul className="navbar-nav flex-grow mr-auto mt-2 mt-lg-0">
            <NavItem>
              <NavLink tag={Link} className="text-light navt" to="/">
                HOME
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light navt" to="/projects">
                {lang === "De" ? "PROJEKTE" : "PROJEKTY"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light navt" to="/about">
                {lang === "De" ? "ÜBER MICH" : "O MNĚ"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-light navt" to="/contact">
                KONTAKT
              </NavLink>
            </NavItem>
            {window.innerWidth > 991 ? (
              <NavItem className="text-light navbar-navll">|</NavItem>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink
                tag={Link}
                to={window.location.pathname}
                className="text-light navt"
                onClick={() => changeLang("")}
              >
                CZ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={window.location.pathname}
                className="text-light navt"
                onClick={() => changeLang("De")}
              >
                DE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-light navt">
                {renderButtonAdmin()}
              </NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavMenu;
