import React from 'react';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router";
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import ImageUploader from './components/AdminSection/Images/ImageUploader';
import ProjectsUpload from './components/AdminSection/Projects/ProjectsUpload';
import { ApplicationProvider } from './providers/ApplicationProvider';
import LoginPage from './components/AdminSection/Auth/LoginPage';
import ChangePassword from './components/AdminSection/Auth/ChangePassword';
import ForgotPassword from './components/AdminSection/Auth/ForgotPassword';
import AdminList from './components/AdminSection/AdminList';
import ResetPassword from './components/AdminSection/Auth/ResetPassword';
import AccessDenied from './components/Pages/AccessDenied';
import NotFound from './components/Pages/NotFound';
import AboutEditor from './components/AdminSection/About/AboutEditor';
import AboutEditorDe from './components/AdminSection/About/AboutEditorDe';
import AboutSelect from './components/AdminSection/About/AboutSelect';
import ProjectsList from './components/AdminSection/Projects/ProjectsList';
import ImageList from './components/AdminSection/Images/ImageList';
import ImageEdit from './components/AdminSection/Images/ImageEdit';
import ProjectEdit from './components/AdminSection/Projects/ProjectEdit';
import IntroEditCZE from './components/AdminSection/Intro/IntroEdit';
import IntroEditDE from './components/AdminSection/Intro/IntroEditDe';
import IntroSelect from './components/AdminSection/Intro/IntroSelect';
import ProjectView from './components/ProjectView';
import DescriptionCZE from './components/AdminSection/Projects/DescriptionCZE';
import DescriptionDE from './components/AdminSection/Projects/DescriptionDE';
import SortProject from './components/AdminSection/Projects/SortProject';
import SortImage from './components/AdminSection/Images/SortImage';
import FakturyUpload from './components/AdminSection/Faktury/FakturyUpload';
import FakturyList from './components/AdminSection/Faktury/FakturyList';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'

function App() {
  const history = useHistory();

  return (
    <div className="App">
      <ApplicationProvider>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/project/:id" component={ProjectView} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/admin" component={LoginPage} /> 
            <Route exact path="/admin/list" component={AdminList} />
            <Route exact path="/admin/faktury/upload" component={FakturyUpload} />
            <Route exact path="/admin/faktury/list" component={FakturyList} />
            <Route exact path="/admin/image/upload/:id" component={ImageUploader} />
            <Route exact path="/admin/image/list/:id" component={ImageList} />
            <Route exact path="/admin/image/edit/:id" component={ImageEdit} />
            <Route exact path="/admin/image/sort/:id" component={SortImage} />
            <Route exact path="/admin/project/upload" component={ProjectsUpload} />
            <Route exact path="/admin/project/list" component={ProjectsList} />
            <Route exact path="/admin/project/sort" component={SortProject} />
            <Route exact path="/admin/project/edit/:id" component={ProjectEdit} />
            <Route exact path="/admin/project/edit/description/cze/:id" component={DescriptionCZE} />
            <Route exact path="/admin/project/edit/description/de/:id" component={DescriptionDE} />
            <Route exact path="/admin/about/edit/cze" component={AboutEditor} />
            <Route exact path="/admin/about/edit/de" component={AboutEditorDe} />
            <Route exact path="/admin/about/edit/select" component={AboutSelect} />
            <Route exact path="/admin/intro/edit/cze" component={IntroEditCZE} />
            <Route exact path="/admin/intro/edit/de" component={IntroEditDE} />
            <Route exact path="/admin/intro/edit/select" component={IntroSelect} />
            <Route exact path="/password/forgot" component={ForgotPassword} />
            <Route exact path="/password/change" component={ChangePassword} />
            <Route path="/password/reset" component={ResetPassword} />
            <Route exact path="/access/denied" component={AccessDenied} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </ApplicationProvider>
    </div>
  );
}

export default App;