import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import {
  Button,
  Input,
  Label,
  Card,
  CardBody,
  Container,
  ButtonGroup,
  Alert,
} from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import Loading from "../../Pages/Loading";

const ProjectEdit = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState(false);
  const [isActive, setIsActive] = useState();
  const [done, setDone] = useState(false);
  const editorRef = useRef(null);
  const [name, setName] = useState();
  const [nameDe, setNameDe] = useState();
  const [type, setType] = useState();
  const [typeDe, setTypeDe] = useState();
  const [year, setYear] = useState();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        setResponse(res.data);
        setName(res.data.name);
        setNameDe(res.data.nameDe);
        setType(res.data.type);
        setTypeDe(res.data.typeDe);
        setIsActive(res.data.isActive);
        setYear(res.data.year);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, []);

  function sendData() {
    const values = {
      Id: id,
      Name: name,
      NameDe: nameDe,
      Type: type,
      TypeDe: typeDe,
      Year: year,
      isActive: isActive,
      Description: response.Description,
      DescriptionDe: response.DescriptionDe,
    };
    var data = JSON.stringify(values);
    setDone(false);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Projects`,
        {
          payload: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        history.push(`/admin/project/edit/${id}`);
      })
      .catch((err) => {
      })
      .finally((res) => {
        setDone(true);
      });
  }

  function renderAlert() {
    if (done) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Alert isOpen={visible} color="success" className="mt-3">
              Projekt byl upraven
              <Button
                className="mx-3"
                size="sm"
                color="success"
                onClick={onDismiss}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </Button>
            </Alert>
          </div>
        </>
      );
    }
  }

  const handleChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };
  const handleChangeNameDe = (e) => {
    e.preventDefault();
    setNameDe(e.target.value);
  };
  const handleChangeType = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };
  const handleChangeTypeDe = (e) => {
    e.preventDefault();
    setTypeDe(e.target.value);
  };
  const handleChangeYear = (e) => {
    e.preventDefault();
    setYear(e.target.value);
  };

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <Container>
            <div className="text-center">
              <Button
                className="btn-primary mt-3 mb-3 mx-3"
                tag={Link}
                to="/admin/project/list"
              >
                Zpět na výpis projektů
              </Button>
              <Button
                className="btn-success mt-3 mb-3"
                tag={Link}
                to={`/admin/image/upload/${id}`}
              >
                Přidat obrázky do projektu
              </Button>
            </div>
            {renderAlert()}
            <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <div className="col-xs-2">
                  <Label className="text-white mt-1">
                    Název projektu (CZE)
                  </Label>
                  <Input
                    className="bg-light col-xs-2"
                    onChange={handleChangeName}
                    value={name}
                  />
                  <Label className="text-white mt-1">Název projektu (DE)</Label>
                  <Input
                    className="bg-light col-xs-2"
                    onChange={handleChangeNameDe}
                    value={nameDe}
                  />
                  <Label className="text-white mt-1">Typ projektu (CZE)</Label>
                  <Input
                    className="bg-light col-xs-2"
                    onChange={handleChangeType}
                    value={type}
                  />
                  <Label className="text-white mt-1">Typ projektu (DE)</Label>
                  <Input
                    className="bg-light col-xs-2"
                    onChange={handleChangeTypeDe}
                    value={typeDe}
                  />
                  <Label className="text-white mt-1">Rok projektu</Label>
                  <Input
                    className="bg-light col-xs-2"
                    onChange={handleChangeYear}
                    value={year}
                  />
                </div>
                <p className="text-white mt-3">
                  Je projekt aktivní? {isActive ? <i>"ANO"</i> : <i>"NE"</i>}
                </p>
                <div class="d-flex justify-content-between ml-1">
                  <ButtonGroup>
                    <Button
                      color="warning"
                      className="mt-3 mb-3"
                      onClick={() => setIsActive(true)}
                    >
                      Aktivní
                    </Button>
                    <Button
                      color="warning"
                      className="mt-3 mb-3"
                      onClick={() => setIsActive(false)}
                    >
                      Neaktivní
                    </Button>
                  </ButtonGroup>
                  <Button
                    type="submit"
                    className="mt-3 mb-3"
                    color="success"
                    onClick={() => sendData()}
                  >
                    Uložit
                  </Button>
                  <ButtonGroup>
                    <Button
                      className="btn-primary mt-3 mb-3"
                      tag={Link}
                      to={`/admin/project/edit/description/cze/${id}`}
                    >
                      Upravit popis (CZE)
                    </Button>
                    <Button
                      className="btn-primary mt-3 mb-3"
                      tag={Link}
                      to={`/admin/project/edit/description/de/${id}`}
                    >
                      Upravit popis (DE)
                    </Button>
                  </ButtonGroup>
                </div>
              </CardBody>
            </Card>
          </Container>
        </>
      );
    }
  }
};

export default ProjectEdit;
