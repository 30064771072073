import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Card,
  CardBody,
  Container,
  ButtonGroup
} from "reactstrap";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "../../style/style.css";
import {
  useAuthContext,
} from "../../../providers/ApplicationProvider";
import Navmenu from "../Navmenu";
import AccessDenied from "../../Pages/AccessDenied";

const validate = (values) => {
  const errors = {};
  if (!values.Name) errors.Name = "Napiš jméno v CZE";
  if (!values.NameDe) errors.NameDe = "Napiš jméno v DE";
  if (!values.Type) errors.Type = "Napiš typ v CZE";
  if (!values.TypeDe) errors.TypeDe = "Napiš typ v DE";
  if (!values.Year) errors.Year = "Napiš rok";
  return errors;
};

const ProjectsUpload = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [isActive, setIsActive] = useState();

  const formik = useFormik({
    initialValues: {
      Name: "",
      NameDe: "",
      Type: "",
      TypeDe: "",
      Year: "",
      Description: "",
      DescriptionDe: "",
      IsActive: false,
    },
    validate: validate,
    onSubmit: (values) => {
      var data = JSON.stringify(values);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/Projects`,
          { payload: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/admin/project/list");
        })
        .catch((err) => {
        });
    },
  });

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <div>
        <Navmenu />
        <Container>
          <div className="text-center">
            <Button
              className="btn-primary mt-3 mb-3 mx-3"
              tag={Link}
              to="/admin/project/list"
            >
              Zpět na výpis projektů
            </Button>
          </div>
          <FormikProvider value={formik}>
            <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="Name">Název projektu (CZE)</Label>
                    <Input
                      name="Name"
                      id="Name"
                      placeholder="Název projektu (CZE)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      invalid={Boolean(formik.errors.Name)}
                      valid={formik.touched.Name}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="NameDe">Název projektu (DE)</Label>
                    <Input
                      name="NameDe"
                      id="NameDe"
                      placeholder="Název projektu (DE)"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.NameDe}
                      invalid={Boolean(formik.errors.NameDe)}
                      valid={formik.touched.NameDe}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="Type">Typ projektu (CZE)</Label>
                    <Input
                      name="Type"
                      id="Type"
                      placeholder="Typ projektu (CZE)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Type}
                      invalid={Boolean(formik.errors.Type)}
                      valid={formik.touched.Type}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="TypeDe">Typ projektu (DE)</Label>
                    <Input
                      name="TypeDe"
                      id="TypeDe"
                      placeholder="Typ projektu (DE)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.TypeDe}
                      invalid={Boolean(formik.errors.TypeDe)}
                      valid={formik.touched.TypeDe}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="Year">Rok projektu</Label>
                    <Input
                      name="Year"
                      id="Year"
                      placeholder="Rok projektu"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Year}
                      invalid={Boolean(formik.errors.Year)}
                      valid={formik.touched.Year}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="Description">Popis (CZE)</Label>
                    <Input
                      type="textarea"
                      name="Description"
                      id="Description"
                      placeholder="Popis projektu (CZE)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Description}
                      invalid={Boolean(formik.errors.Description)}
                      valid={formik.touched.Description}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="DescriptionDe">Popis (DE)</Label>
                    <Input
                      type="textarea"
                      name="DescriptionDe"
                      id="DescriptionDe"
                      placeholder="Popis projektu (DE)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.DescriptionDe}
                      invalid={Boolean(formik.errors.DescriptionDe)}
                      valid={formik.touched.DescriptionDe}
                    />
                  </FormGroup>
                  <p className="text-white mt-3 mx-2">Je projekt aktivní? {(isActive) ? <i>"ANO"</i> : <i>"NE"</i>}</p>
                  <div>
                    <ButtonGroup className="m-2">
                      <Button color="warning" onClick={() => setIsActive(true)}>
                        Aktivní
                      </Button>
                      <Button color="warning" onClick={() => setIsActive(false)}>
                        Neaktivní
                      </Button>
                    </ButtonGroup>
                    <Button type="submit" className="m-2" color="success">
                      Uložit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </FormikProvider>
        </Container>
      </div>
    );
  }
};

export default ProjectsUpload;
