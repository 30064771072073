import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Button, Container } from "reactstrap";

const NotFound = () => {
  return (
    <>
      <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <Card className="bg-dark shadow-lg p-3 mb-5 bg-dark rounded col-sm-6">
          <CardBody>
            <CardTitle tag="h1" className="text-white">
              ERROR 404 - NOT FOUND
            </CardTitle>
            <Button className="mt-5 mb-3" color="danger" tag={Link} to="/">
              Zpět na hlavní stránku
            </Button>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default NotFound;
