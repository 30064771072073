import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faPinterestSquare,
} from "@fortawesome/free-brands-svg-icons";
import "../style/style.css";
import { Col, Row } from "reactstrap";
import Yuki from "../assets/images/navbarpngw.svg";

const Footer = () => {
  return (
    <footer className="footer text-center text-white p-3">
      <Row>
        <Col sm className="mt-3">
          <h1>KONTAKT</h1>
          <Row>
            <a className="tel" href="mailto:veri.sulkova@gmail.com">
              veri.sulkova@gmail.com
            </a>
          </Row>
          <Row>
            <a
              className="tel"
              href="https://www.mojedatovaschranka.cz/sds/detail?dbid=k4yq359"
              target="_blank"
              rel="noreferrer"
            >
              ID datové schránky: k4yq359
            </a>
          </Row>
          <Row>
            <a
              className="tel"
              href="https://rejstriky.finance.cz/firma-dipl-ing-veronika-sulkova-m-sc-05236541"
              target="_blank"
              rel="noreferrer"
            >
              IČO: 05236541
            </a>
          </Row>
          <Row>
            <a className="tel" href="tel:+420 721 518 460">
              +420 721 518 460
            </a>
          </Row>
          <Row className="mt-2">
            <div className="flexbox-centering">
              <a
                href="https://www.facebook.com/yukikitadesign/"
                target="_blank"
                className="btn-floating btn-sm"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="fbico"
                  size="2x"
                  icon={faFacebookSquare}
                />
              </a>
              <a
                href="https://www.instagram.com/8yukikita8/"
                target="_blank"
                className="btn-floating btn-sm"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="igico"
                  size="2x"
                  icon={faInstagram}
                />
              </a>
              <a
                href="https://cz.pinterest.com/yukikitadesign/_saved/"
                target="_blank"
                className="btn-floating btn-sm"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="pintico"
                  size="2x"
                  icon={faPinterestSquare}
                />
              </a>
            </div>
          </Row>
        </Col>
        <Col sm className="mt-3">
          <p>
            <br></br>
          </p>
          <img src={Yuki} className="footersvg mb-3" alt="logo" />
          <Row>
            <a>{new Date().getFullYear()} © Veronika Sulková</a>
          </Row>
        </Col>
      </Row>
      <Row className="text-center cby fs-6">
        <a
          href="https://tomas-kulhavy.cz/"
          target="_blank"
          className="cby mt-5"
          rel="noreferrer"
        >
          Made with ❤️ by @tomaskulhavy
        </a>
      </Row>
    </footer>
  );
};

export default Footer;
