import React from "react";
import Yuki from "../assets/images/icoyukiBlack.svg";
import "./style.css";
import "../style/hover.css";

const Loading = () => {
  return (
    <>
      <section className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center bg-white">
        <img src={Yuki} className="yukiIcoLoading mb-3" alt="logo" />
      </section>
    </>
  );
};
export default Loading;
