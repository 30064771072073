import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Yuki from "./assets/images/yuki.jpg";
import { Row, Col, Alert } from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import axios from "axios";
import "./style/style.css";
import "./style/aboutme.css";
import { ApplicationContext } from "../providers/ApplicationProvider";
import Loading from "./Pages/Loading";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [{ lang }] = useContext(ApplicationContext);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/aboutme/about`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data[0]);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderAbout() {
    if (lang === "De") return response.descriptionDe;
    else if (lang === "") return response.description;
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (error) {
    return <Alert color="danger">Při získávání dat došlo k chybě.</Alert>;
  } else if (response) {
    return (
      <>
        <Helmet>
          <title>Veronika Sulková | O mně | Architektura a design</title>
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | O mně | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta property="og:url" content="https://veronika-sulkova.eu/about" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Veronika Sulková | O mně | Architektura a design"
          />
          <meta
            property="og:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            property="og:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Veronika Sulková | O mně | Architektura a design"
          />
          <meta
            name="twitter:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            name="twitter:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | O mně | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
        </Helmet>
        <LayoutAll>
          <section className="intro plain bg-white pt-5">
            <div className="inner-content container">
              <Row>
                <Col md className="heading">
                  <UnsafeComponent html={renderAbout()} />
                </Col>
                <Col md className="text-center">
                  <img alt="yuki" class="yuki" src={Yuki} />
                </Col>
              </Row>
            </div>
          </section>
        </LayoutAll>
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default Projects;
