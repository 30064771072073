import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import Navmenu from "../Navmenu";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { Button } from "reactstrap";

const IntroEditDe = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const editorRef = useRef(null);
  const history = useHistory();

  const log = () => {
    if (editorRef.current) {
      response.description = editorRef.current.getContent();
      var data = JSON.stringify(response);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/AboutMe/about`,
          { payload: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/admin/list");
        })
        .catch((err) => {
        });
    }
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/about`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data[0]);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <div className="container mt-3 border-radius shadow-lg p-1 bg-dark rounded">
            <Editor
              apiKey="ul6mdzl069gm6wnobufb6hvemuyx42jga41vk5v0g4i5uay9"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={response.description}
              init={{
                height: 600,
                menubar: false,
                plugins: [
                  "advlist autolink lists table link charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help " +
                  "insertfile media pageembed template link anchor codesample",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                imagetools_toolbar:
                  "rotateleft rotateright | flipv fliph | editimage imageoptions",
                contextmenu: "link image table",
                menubar: "file edit view insert format tools table tc help",
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");

                  /*
                      Note: In modern browsers input[type="file"] is functional without
                      even adding it to the DOM, but that might not be the case in some older
                      or quirky browsers like IE, so you might want to add it to the DOM
                      just in case, and visually hide it. And do not forget do remove it
                      once you do not need it anymore.
                    */

                  input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                      /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                      var id = "blobid" + new Date().getTime();
                      var blobCache =
                      window.tinymce.activeEditor.editorUpload.blobCache;
                      var base64 = reader.result.split(",")[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
              }}
            />
            <Button className="btn btn-success mt-2" onClick={log}>
              Uložit
            </Button>
          </div>
        </>
      );
    }
  }
};

export default IntroEditDe;
