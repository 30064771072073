import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardTitle,
  Button,
  Container,
} from "reactstrap";
import Navmenu from "./Navmenu";
import "./adminBg.css";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";

const AdminList = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  if(!(accessToken)) {
      return (
          <>
            <AccessDenied />
          </>
      )
  }

  if (accessToken) {
    return (
      <>
        <Navmenu />
        <Container className="text-center">
          <h1 className="text-light display-1 mt-3">Admin sekce</h1>
          <Row className="mt-5">
            <Col className="mt-2">
              <Card
                body
                inverse
                className="h-100 d-flex flex-column"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Úvodní stránka</CardTitle>
                <Button tag={Link} to="/admin/intro/edit/select" className="mt-auto">
                  Upravit
                </Button>
              </Card>
            </Col>
            <Col className="mt-2">
              <Card
                body
                inverse
                className="h-100 d-flex flex-column"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">O mně</CardTitle>
                <Button tag={Link} to="/admin/about/edit/select" className="mt-auto">
                  Upravit
                </Button>
              </Card>
            </Col>
            <Col className="mt-2">
              <Card
                body
                inverse
                className="h-100 d-flex flex-column"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Projekty</CardTitle>
                <Button tag={Link} to="/admin/project/list" className="mt-auto">
                  Upravit
                </Button>
              </Card>
            </Col>
            <Col className="mt-2">
              <Card
                body
                inverse
                className="h-100 d-flex flex-column"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Faktury</CardTitle>
                <Button tag={Link} to="/admin/faktury/list" className="mt-auto">
                  Upravit
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default AdminList;
