import React, { useCallback, useEffect, useState, useContext } from "react";
import { Alert, Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "../Navmenu";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { ReactSortable } from "react-sortablejs";
import {
  faTimes,
  faCheck,
  faAlignJustify,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/style.css"

const SortImage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const { id } = useParams();

  const [{ accessToken }] = useContext(ApplicationContext);

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Images/sort/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    function renderCheck(check) {
      if (check) {
        return (
          <>
            <Button color="success" refresh="true" disabled={check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
      if (!check) {
        return (
          <>
            <Button color="secondary" refresh="true" disabled={!check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
    }
    const array = response.map((item) => {
      return (
        <tr className="text-white align-middle my-3 mx-3" key={item.id}>
          <td>
            <FontAwesomeIcon icon={faAlignJustify} className="fs-6" />
          </td>
          <td>
            <img
              src={`data:image/jpeg;base64,${item.preview}`}
              className="imgSort"
            />
          </td>
        </tr>
      );
    });
    return array;
  }

  function sendData(index) {
    var data = response[index];
    setDone(false);
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/Images`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .then(() => {
        setDone(true);
      });
  }

  function sortProjects() {
    response.map((item, index) => {
      item.position = index;
      sendData(index);
    });
  }

  function renderSuccess() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            Pozice upraveny
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <Container className="col-md-3">
            {renderSuccess()}
            {renderError()}
            <Button
              className="mt-3 btn-primary"
              tag={Link}
              to={`/admin/image/list/${id}`}
            >
              Výpis obrázků
            </Button>
            <Table responsive className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded">
              <ReactSortable
                group="groupName"
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={response}
                setList={setResponse}
              >
                {renderProjects()}
              </ReactSortable>
            </Table>
            <Button className="btn btn-success" onClick={() => sortProjects()}>
              Uložit
            </Button>
          </Container>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default SortImage;
