import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import { Link } from "react-router-dom";
import Loading from "./Pages/Loading";
import { ApplicationContext } from "../providers/ApplicationProvider";
import "./style/style.css";
import "./style/hover.css";
import "./style/projects.css";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [{ lang }] = useContext(ApplicationContext);

  const history = useHistory();

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects/getAll`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history]);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    const array = response.map((item) => {
      function renderName() {
        if (lang === "De") return item.nameDe;
        else if (lang === "") return item.name;
      }
      function renderType() {
        if (lang === "De") return item.typeDe;
        else if (lang === "") return item.type;
      }
      return (
        <>
          <Col md className="my-3" key={item.id}>
            <div className="content">
              <Link tag={Link} to={`/project/${item.projectId}`}>
                <div className="content-overlay"></div>
                <img
                  src={`data:image/jpeg;base64,${item.picture}`}
                  className="content-image"
                />
                <div className="content-details fadeIn-bottom">
                  <h4 className="content-title">{renderName()}</h4>
                  <p className="content-text">{renderType()}</p>
                </div>
              </Link>
            </div>
          </Col>
        </>
      );
    });
    return array;
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Veronika Sulková | Projekty | Architektura a design</title>
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | Projekty | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta
            property="og:url"
            content="https://veronika-sulkova.eu/projects"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Veronika Sulková | Projekty | Architektura a design"
          />
          <meta
            property="og:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            property="og:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Veronika Sulková | Projekty | Architektura a design"
          />
          <meta
            name="twitter:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            name="twitter:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | Projekty | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
        </Helmet>
        <LayoutAll>
          <div className="appColor">
            <div class="showcaseProjectPage">
              <div class="captionProjectPage font-weight-bold">
                <h1 class="display-1 text-white align-middle">
                  {lang === "De" ? "PROJEKTE" : "PROJEKTY"}
                </h1>
              </div>
            </div>
            <div class="container mt-3">
              <Row className="pb-3">{renderProjects()}</Row>
            </div>
          </div>
        </LayoutAll>
      </>
    );
  }
};

export default Projects;
