import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardTitle,
  Button,
  Container,
} from "reactstrap";
import Navmenu from "../Navmenu";
import "../adminBg.css";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

const AdminList = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  if(!(accessToken)) {
      return (
          <>
            <AccessDenied />
          </>
      )
  }

  if (accessToken) {
    return (
      <>
        <Navmenu />
        <Container className="text-center">
          <h1 className="text-light display-1 mt-3">Vyber si jazyk</h1>
          <Row className="mt-3 d-flex justify-content-evenly">
            <Col sm={4} className="mt-2">
              <Card
                body
                inverse
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">CZE</CardTitle>
                <Button tag={Link} to="/admin/about/edit/cze">
                  Upravit
                </Button>
              </Card>
            </Col>
            <Col sm={4} className="mt-2">
              <Card
                body
                inverse
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">DE</CardTitle>
                <Button tag={Link} to="/admin/about/edit/de">
                  Upravit
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default AdminList;
