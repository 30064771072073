import React, { useState, useContext, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Container,
  Button,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../style/style.css";
import Yuki from "../assets/images/navbarpngw.svg";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";

const Navmenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, []);

  const logout = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Account/logout`);
  };

  return (
    <Navbar
      style={{ backgroundColor: "rgb(0,0,0)" }}
      className="navbar navbar-expand-lg navbar-toggleable-lg navbar-dark sticky-top"
    >
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img src={Yuki} className="navbarsvg mb-1 mt-1" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse
          className="d-lg-inline-flex flex-lg-row-reverse"
          isOpen={isOpen}
          navbar
        >
          <ul className="navbar-nav d-flex align-middle flex-grow mr-auto mt-2 mt-lg-0">
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt navbar-navi"
                to="/"
              >
                <Button className="btn btn-success">HOME</Button>
              </NavLink>
            </NavItem>
            {window.innerWidth > 991 ? (
              <NavItem className="text-light navbar-navi">|</NavItem>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt navbar-navi"
                to="/admin/list"
              >
                <Button className="btn btn-primary">Admin sekce</Button>
              </NavLink>
            </NavItem>
            {window.innerWidth > 991 ? (
              <NavItem className="text-light navbar-navi">|</NavItem>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt"
                to="/admin/intro/edit/select"
              >
                Úvodní stránka
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt"
                to="/admin/about/edit/select"
              >
                O mně
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt"
                to="/admin/project/list"
              >
                Projekty
              </NavLink>
            </NavItem>
            {window.innerWidth > 991 ? (
              <NavItem className="text-light navbar-navll">|</NavItem>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt"
                onClick={() => logout()}
                to="/"
              >
                <Button className="btn btn-warning">Odhlásit se</Button>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className="text-light navt"
                to="/password/change"
              >
                <Button className="btn btn-warning">Změnit heslo</Button>
              </NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Navmenu;
