import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "./layout/Layout";
import yuki from "./assets/images/yuki.jpg";
import "./style/style.css";
import "./style/hover.css";
import { Row, Col } from "reactstrap";
import axios from "axios";
import Loading from "./Pages/Loading";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Helmet } from "react-helmet";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [responseImage, setResponseImage] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [{ lang }] = useContext(ApplicationContext);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/AboutMe/intro`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data[0]);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects/getAll`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponseImage(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history]);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderIntro() {
    if (lang === "De") return response.descriptionDe;
    else if (lang === "") return response.description;
  }

  function renderProjects() {
    const array = responseImage.map((item) => {
      function renderName() {
        if (lang === "De") return item.nameDe;
        else if (lang === "") return item.name;
      }
      function renderType() {
        if (lang === "De") return item.typeDe;
        else if (lang === "") return item.type;
      }
      return (
        <>
          <Helmet>
            <title>Veronika Sulková | Architektura a design</title>
            <meta
              name="description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
            <meta
              itemprop="name"
              content="Veronika Sulková | Architektura a design"
            />
            <meta
              itemprop="description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
            <meta
              itemprop="image"
              content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
            />
            <meta property="og:url" content="https://veronika-sulkova.eu/" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Veronika Sulková | Architektura a design"
            />
            <meta
              property="og:description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
            <meta
              property="og:image"
              content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Veronika Sulková | Architektura a design"
            />
            <meta
              name="twitter:description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
            <meta
              name="twitter:image"
              content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
            />
            <meta
              name="description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
            <meta
              itemprop="name"
              content="Veronika Sulková | Architektura a design"
            />
            <meta
              itemprop="description"
              content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
            />
          </Helmet>
          <Col md className="my-3">
            <div className="content" key={item.id}>
              <Link to={`/project/${item.projectId}`}>
                <div className="content-overlay"></div>
                <img
                  src={`data:image/jpeg;base64,${item.picture}`}
                  className="content-image"
                  alt="project image"
                />
                <div className="content-details fadeIn-bottom">
                  <h4 className="content-title">{renderName()}</h4>
                  <p className="content-text">{renderType()}</p>
                </div>
              </Link>
            </div>
          </Col>
        </>
      );
    });
    return array;
  }
  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Layout>
          <div className="appColor">
            <div className="">
              <div className="caption pb-10 font-weight-bold">
                <h1 className="display-1 text-white">Veronika Sulková</h1>
                <h1 className="fs-3 text-white">
                  {lang === "De"
                    ? "Architektur und Design"
                    : "Architektura a design"}
                </h1>
              </div>
            </div>
            <svg className="arrows captionArrow">
              <path className="a1" d="M0 0 L30 32 L60 0"></path>
              <path className="a2" d="M0 20 L30 52 L60 20"></path>
            </svg>
            <div className="container">
              <div className="text-center py-4">
                <div>
                  <Row>
                    <Col lg className="my-1">
                      <div className="fs-5 text-dark container" id="aboutme">
                        <UnsafeComponent html={renderIntro()} />
                      </div>
                    </Col>
                    <Col lg className="my-1">
                      <img
                        className="yuki"
                        src={yuki}
                        alt="Veronika Sulková"
                      ></img>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="showcase2">
              <div className="captionProject font-weight-bold">
                <h1 className="display-1 text-white align-middle">
                  {lang === "De" ? "PROJEKTE" : "PROJEKTY"}
                </h1>
              </div>
            </div>
            <div className="container mt-3">
              <Row className="pb-3">{renderProjects()}</Row>
            </div>
          </div>
        </Layout>
      </>
    );
  }
};

export default Home;
