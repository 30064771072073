import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CardBody,
  Alert,
  CardFooter,
  Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useFormik, FormikProvider } from "formik";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../../providers/ApplicationProvider";
import "../../style/style.css";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "";
  }
  if (!values.password) {
    errors.password = "";
  }
  return errors;
};

const SignIn = () => {
  const history = useHistory();
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      })
  }, [dispatch, accessToken]);

  function renderAlert() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Někde se stala chyba, zkuste to znovu nebo kontaktujte správce!
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: validate,
    onSubmit: (values) => {
      setError(false);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/Account/login`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: SET_ACCESS_TOKEN,
            payload: response.data.accessToken,
          });
          history.push("/admin/list");
        })
        .catch(() => {
          setError(true);
        });
    },
  });

  if (accessToken) {
    history.push("/admin/list");
    window.location.reload();
  } 
  
  if(!(accessToken)) {
    return (
      <>
        <Container>
          <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <FormikProvider value={formik}>
              {renderAlert()}
              <Card className="border-radius col-sm-6 zarovnat shadow-lg p-3 mb-5 bg-dark rounded">
                <CardBody className="bg-dark text-light">
                  <div className="d-flex align-items-start">
                    <div className="font-weight-bold">
                      <small className="text-white-70 d-block font-size-xl mb-1 text-uppercase">
                        Přihlásit se
                      </small>
                      <span className="font-size-xxl mt-1"></span>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="btn-dark mt-2 mb-2" tag={Link} to="/">
                      Zpět na domovskou obrazovku
                    </Button>
                  </div>
                  <Form onSubmit={formik.handleSubmit}>
                    <FormGroup className="m-2">
                      <Label for="email">E-mailová adresa</Label>
                      <Input
                        name="email"
                        id="email"
                        type="email"
                        placeholder="jannovak@email.cz"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        invalid={Boolean(formik.errors.email)}
                        valid={formik.touched.email}
                      />
                      {formik.errors.email ? (
                        <FormFeedback invalid>
                          {formik.errors.email}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="m-2">
                      <Label for="password">Heslo</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Heslo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        invalid={Boolean(formik.errors.password)}
                        valid={formik.touched.password}
                      />
                      {formik.errors.password ? (
                        <FormFeedback invalid>
                          {formik.errors.password}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <div>
                      <Button type="submit" className="m-2" color="success">
                        Přihlásit se
                      </Button>
                    </div>
                  </Form>
                </CardBody>
                <CardFooter className="text-center bg-dark text-light">
                  <Button
                    tag={Link}
                    to="/password/forgot"
                    className="mr-2 mb-2"
                    color="warning"
                  >
                    Zapomenuté heslo
                  </Button>
                </CardFooter>
              </Card>
            </FormikProvider>
          </div>
        </Container>
      </>
    );
  }
}

export default SignIn;
