import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import LayoutAll from "./layout/LayoutAll";
import "./style/projects.css";
import "./style/hover.css";
import Loading from "./Pages/Loading";
import { ApplicationContext } from "../providers/ApplicationProvider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Row, Button, ButtonGroup } from "reactstrap";
import {
  faGreaterThan,
  faLessThan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

const ProjectView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [image, setImage] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [{ lang }] = useContext(ApplicationContext);
  const [open, setOpen] = React.useState(false);
  const [tempIndex, setTempIndex] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Projects/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/projects");
      })
      .finally(() => {});
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Images/getImages/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setImage(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setImage(null);
        }
        history.push("/projects");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  function renderImages() {
    return (
      <Row className="pt-3 pb-3">
        {image.map((item, index) => (
          <>
            <div class="col-md my-3">
              <div
                class="content"
                onClick={() => {
                  handleClickOpen();
                  setTempIndex(index);
                }}
              >
                <div class="content-overlayIco"></div>
                <img
                  src={`data:image/jpeg;base64,${item.preview}`}
                  className="content-image"
                />
                <div class="content-detailsIco fadeIn-bottom">
                  <i class="yukiIco content-title"></i>
                </div>
              </div>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle>
                <p className="fs-5">
                  {lang === "De"
                    ? image[tempIndex].descriptionDe
                    : image[tempIndex].description}
                </p>
              </DialogTitle>
              <DialogContent>
                <img
                  className="content-imageModal"
                  src={`data:image/jpeg;base64,${image[tempIndex].preview}`}
                />
              </DialogContent>
              <DialogActions>
                <ButtonGroup>
                  <Button
                    color="transparent"
                    outline
                    onClick={() =>
                      tempIndex - 1 < 0
                        ? setTempIndex(0)
                        : setTempIndex(tempIndex - 1)
                    }
                  >
                    <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
                  </Button>
                  <Button
                    color="transparent"
                    outline
                    onClick={() =>
                      tempIndex + 1 > image.length - 1
                        ? setTempIndex(image.length - 1)
                        : setTempIndex(tempIndex + 1)
                    }
                  >
                    <FontAwesomeIcon icon={faGreaterThan}></FontAwesomeIcon>
                  </Button>
                </ButtonGroup>
                <Button
                  onClick={handleClose}
                  color="transparent"
                  outline
                  autoFocus
                >
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ))}
      </Row>
    );
  }

  function renderName() {
    if (lang === "De") return response.nameDe;
    else if (lang === "") return response.name;
  }

  function renderType() {
    if (lang === "De") return response.typeDe;
    else if (lang === "") return response.type;
  }

  function renderDescription() {
    if (lang === "De") return response.descriptionDe;
    else if (lang === "") return response.description;
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>Veronika Sulková | Architektura a design</title>
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta property="og:url" content="https://veronika-sulkova.eu/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Veronika Sulková | Architektura a design"
          />
          <meta
            property="og:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            property="og:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Veronika Sulková | Architektura a design"
          />
          <meta
            name="twitter:description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            name="twitter:image"
            content="https://veronika-sulkova.eu/static/media/yuki.1f1dfb4821017bbcc5d9.jpg"
          />
          <meta
            name="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
          <meta
            itemprop="name"
            content="Veronika Sulková | Architektura a design"
          />
          <meta
            itemprop="description"
            content="Nabízím kompletní servis v oblasti projektové činnosti a to od návrhu až po jeho realizaci včetně interiéru na míru."
          />
        </Helmet>
        <LayoutAll>
          <section class="intro plain">
            <div class="inner-content container">
              <div class="row">
                <div class="heading">
                  <h1 class="hugHeading right">{renderName()}</h1>
                </div>
              </div>
              <div class="row">
                <div class="description">
                  <p class="hug text-white">
                    {renderType()} | {response.year}
                  </p>
                  <br></br>
                  <p class="hug text-white">
                    <UnsafeComponent html={renderDescription()} />
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="bg-white">
            <div class="container">
              <div>{renderImages()}</div>
            </div>
          </div>
        </LayoutAll>
      </>
    );
  }
};

export default ProjectView;
