import React, { useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Container, Alert, Row } from "reactstrap";
import Navmenu from "../Navmenu";
import axios from "axios";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "../adminBg.css";

function Previews() {
  const [files, setFiles] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const { id } = useParams();
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function uploadData() {
    files.map((file, index) => ((file.projectId = id), sendData(index)));
  }

  function sendData(index) {
    setUploading(true);
    const data = new FormData();
    data.append(`Image`, files[index]);
    data.append(`Name`, files[index].name);
    data.append("ProjectId", files[index].projectId);
    setDone(false);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/Images`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setDone(true);
        setUploading(false);
      });
  }

  function renderAlert() {
    if (done) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Alert isOpen={visible} color="success" className="mt-3">
              Obrázky byly nahrány!
              <Button
                className="mx-3"
                size="sm"
                color="success"
                onClick={onDismiss}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </Button>
            </Alert>
          </div>
        </>
      );
    }
    if (error) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Alert isOpen={visible} color="danger" className="mt-3">
              Obrázky nebyly nahrány!
              <Button
                className="mx-3"
                size="sm"
                color="danger"
                onClick={onDismiss}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </Button>
            </Alert>
          </div>
        </>
      );
    }
  }

  function renderButton() {
    if (files.length > 0) {
      return (
        <>
          <Button className="btn-success mt-1" onClick={() => uploadData()}>
            {uploading ? (done) ? "Odesláno" : "Odesílám..." : "Uložit"}
          </Button>
        </>
      );
    }
  }

  const images = files.map((file, index) => (
    <div key={file.id} className="mt-1 col-sm-12">
      <Row>
        <img
          src={file.preview}
          style={{ width: "200px", border: "1px solid black" }}
          alt="preview"
        />{" "}
        <Button
          color="danger"
          refresh="true"
          size="sm"
          className="mx-3 col-1"
          onClick={() => {
            removeItem(index);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Row>
    </div>
  ));

  const removeItem = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <>
        {renderAlert()}
        <div
          {...getRootProps()}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <input {...getInputProps()} />
          <p className="border-radius col-sm-6 shadow-lg p-3 mb-5 bg-dark rounded text-light text-center">
            Nahraj obrázky
          </p>
        </div>
        {renderButton()}
        <div>{images}</div>
      </>
    );
  }
}

const ImageUploader = () => {
  const { id } = useParams();
  return (
    <>
      <Navmenu />
      <Container className="text-center">
        <Button
          className="btn-primary mt-3 mx-3"
          tag={Link}
          to={`/admin/image/list/${id}`}
        >
          Na výpis obrázků
        </Button>
      </Container>
      <div className="d-flex flex-column mt-3 justify-content-center align-items-center">
        <Container>
          <Previews />
        </Container>
      </div>
    </>
  );
};

export default ImageUploader;
